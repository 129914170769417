<template>
  <ModuleView v-if="dataLoaded">
    <template slot="buttons">
      <router-link
        tag="a"
        :title="$t('buttons.edit')"
        class="btn btn-success mr-1"
        :to="{ name: 'editorialTag_edit', params: { id: tag.id }}"
        data-test="article_list_btn_edit"
      >
        <i class="fa fa-edit"></i> {{ $t('buttons.edit') }}
      </router-link>
      <ButtonClose route-name="commonTags_list" />
    </template>
    <template slot="body">
      <preloaderFull v-if="!dataLoaded" />
      <div class="row cards-warapper">
        <div class="col-lg-6 tag-detail-warapper">
          <div class="card card-body">
            <h3> {{ $t('tag.geneeaTag.generalInfo.title') }} </h3>
            <p> <span>{{ $t('tag.geneeaTag.title') }}:</span> {{ tag.title }} </p>
            <p> <span>{{ $t('tag.geneeaTag.id') }}:</span> {{ tag.id }} </p>
            <p v-if="tag.geneeaId"> <span>{{ $t('tag.geneeaTag.geneeaId') }}:</span> {{ tag.geneeaId }} </p>
            <p> <span>{{ $t('tag.geneeaTag.generalInfo.createdAt') }}:</span> {{ tag.createdAt | prettyDateTime }} </p>
            <p> <span>{{ $t('tag.geneeaTag.generalInfo.modifiedAt') }}:</span> {{ tag.modifiedAt | prettyDateTime }} </p>
            <p> <span>{{ $t('tag.geneeaTag.type') }}:</span> {{ tag.type }} </p>
            <p> <span>{{ $t('tag.description') }}:</span><span v-html="tag.description" class="embed-description"></span> </p>
            <p>
              <span>{{ $t('tag.image') }}:</span>
              <Media
                v-if="tag.image"
                :media="tag.image"
                :media-usage-type="tagMediaType"
                show-media-info
              />
            </p>
            <p>
              <span> {{ $t('article.related_articles') }}: </span>
              <RelatedArticleList :show-remove-button="false" :relatedArticlesProp="relatedArticles"/>
            </p>
          </div>
        </div>
        <div class="col-lg-6 tag-detail-warapper">
          <!--  event  -->
          <div v-if="tag.type === TagTypes.EVENT" class="card card-body">
            <h3> {{ $t('tag.geneeaTag.event.title') }} </h3>
            <p> <span>{{ $t('tag.geneeaTag.event.startDate') }}:</span> {{ tag.additionalInfo.event.startDate | prettyDateTime }} </p>
            <p> <span>{{ $t('tag.geneeaTag.event.endDate') }}:</span> {{ tag.additionalInfo.event.endDate | prettyDateTime }} </p>
            <p> <span>{{ $t('tag.geneeaTag.location.streetAddress') }}: </span> {{ tag.additionalInfo.event.address  }}</p>
            <p> <span>{{ $t('tag.geneeaTag.location.addressLocation') }}:</span> {{ tag.additionalInfo.event.district  }}</p>
            <p> <span>{{ $t('tag.geneeaTag.location.addressRegion') }}:</span> {{ tag.additionalInfo.event.region  }}</p>
            <p> <span>{{ $t('tag.geneeaTag.location.addressCountry') }}:</span> {{ tag.additionalInfo.event.country  }}</p>
            <p> <span>{{ $t('tag.geneeaTag.location.gps') }}:</span> {{ tag.additionalInfo.event.geo  }}</p>
          </div>
          <!--  location  -->
          <div v-if="tag.type === TagTypes.LOCATION" class="card card-body">
            <h3> {{ $t('tag.geneeaTag.location.title') }} </h3>
            <p> <span>{{ $t('tag.geneeaTag.location.streetAddress') }}: </span> {{ tag.additionalInfo.location.address  }}</p>
            <p> <span>{{ $t('tag.geneeaTag.location.addressLocation') }}:</span> {{ tag.additionalInfo.location.district  }}</p>
            <p> <span>{{ $t('tag.geneeaTag.location.addressRegion') }}:</span> {{ tag.additionalInfo.location.region  }}</p>
            <p> <span>{{ $t('tag.geneeaTag.location.addressCountry') }}:</span> {{ tag.additionalInfo.location.country  }}</p>
            <p> <span>{{ $t('tag.geneeaTag.location.gps') }}:</span> {{ tag.additionalInfo.location.geo  }}</p>
          </div>
          <!--  organisation  -->
          <div v-if="tag.type === TagTypes.ORGANISATION" class="card card-body">
            <h3> {{ $t('tag.geneeaTag.organisation.title') }} </h3>
            <p> <span>{{ $t('tag.geneeaTag.organisation.alternateName') }}: </span> {{ tag.additionalInfo.organisation.alternateName  }}</p>
            <p> <span>{{ $t('tag.geneeaTag.location.streetAddress') }}: </span> {{ tag.additionalInfo.organisation.address  }}</p>
            <p> <span>{{ $t('tag.geneeaTag.location.addressLocation') }}:</span> {{ tag.additionalInfo.organisation.district  }}</p>
            <p> <span>{{ $t('tag.geneeaTag.location.addressRegion') }}:</span> {{ tag.additionalInfo.organisation.region  }}</p>
            <p> <span>{{ $t('tag.geneeaTag.location.addressCountry') }}:</span> {{ tag.additionalInfo.organisation.country  }}</p>
            <p> <span>{{ $t('tag.geneeaTag.location.gps') }}:</span> {{ tag.additionalInfo.organisation.geo  }}</p>
            <p>
              <span>{{ $t('tag.geneeaTag.organisation.logo') }}:</span>
              <Media
                v-if="tag.additionalInfo.organisation.logoImage"
                :media="tag.additionalInfo.organisation.logoImage"
                :media-usage-type="tagMediaType"
                show-media-info
              />
            </p>
          </div>
          <!--  person  -->
          <div v-if="tag.type === TagTypes.PERSON" class="card card-body">
            <h3> {{ $t('tag.geneeaTag.person.title') }} </h3>
            <p> <span>{{ $t('tag.geneeaTag.person.givenName') }}:</span> {{ tag.additionalInfo.person.givenName }} </p>
            <p> <span>{{ $t('tag.geneeaTag.person.familyName') }}:</span> {{ tag.additionalInfo.person.familyName }} </p>
            <p> <span>{{ $t('tag.geneeaTag.person.hasOccupation') }}:</span> {{ tag.additionalInfo.person.hasOccupation }} </p>
            <p> <span>{{ $t('tag.geneeaTag.person.birthDate') }}:</span> {{ tag.additionalInfo.person.birthDate | prettyDateTime}} </p>
            <p> <span>{{ $t('tag.geneeaTag.person.birthPlace') }}:</span> {{ tag.additionalInfo.person.birthPlace }} </p>
            <p> <span>{{ $t('tag.geneeaTag.person.deathDate') }}:</span> {{ tag.additionalInfo.person.deathDate | prettyDateTime}} </p>
            <p> <span>{{ $t('tag.geneeaTag.person.deathPlace') }}:</span> {{ tag.additionalInfo.person.deathPlace }} </p>
          </div>
          <!--  product  -->
          <div v-if="tag.type === TagTypes.PRODUCT" class="card card-body">
            <h3> {{ $t('tag.geneeaTag.product.title') }} </h3>
            <p> <span>{{ $t('tag.geneeaTag.product.manufacturer') }}:</span> {{ tag.additionalInfo.product.manufacturer }} </p>
            <p> <span>{{ $t('tag.geneeaTag.product.model') }}:</span> {{ tag.additionalInfo.product.model}} </p>
          </div>
          <!--  general -->
          <div v-if="tag.type === TagTypes.GENERAL" class="card card-body">
            <h3> {{ $t('tag.geneeaTag.general.title') }} </h3>
          </div>
        </div>
      </div>
    </template>
  </ModuleView>
</template>

<script>
import { mapActions } from 'vuex'
import { TagTypes } from '@/model/ValueObject/TagTypes'
import ModuleView from '@/components/ModuleView'
import ButtonClose from '@/components/shared/ButtonClose'
import PreloaderFull from '@/components/preloader/PreloaderFull.vue'
import Media from '@/components/shared/Media.vue'
import { MEDIA_USAGE_TYPE_TAG } from '@/model/ValueObject/MediaUsageTypes'
import RelatedArticleList from '@/components/article/ArticleRelatedList.vue'

export default {
  name: 'EditorialTagDetail',
  components: {
    RelatedArticleList,
    Media,
    PreloaderFull,
    ModuleView,
    ButtonClose
  },
  data () {
    return {
      dataLoaded: false,
      tag: null,
      relatedArticles: [],
      tagMediaType: MEDIA_USAGE_TYPE_TAG
    }
  },
  computed: {
    TagTypes () {
      return TagTypes
    }
  },
  methods: {
    ...mapActions('tag', {
      fetchTag: 'fetchOne'
    }),
    async getTag () {
      this.dataLoaded = false
      try {
        const response = await this.fetchTag(this.$route.params.id)
        this.tag = response
        if (Array.isArray(response.relatedArticlesIds) && response.relatedArticlesIds.length > 0) {
          this.relatedArticles = await this.$store.dispatch('tag/fetchArticles', response.relatedArticlesIds)
        }
        this.dataLoaded = true
      } catch (error) {
        this.dataLoaded = true
        console.error('Error fetching tag:', error)
      }
    }
  },
  mounted () {
    this.getTag()
  }
}
</script>

<style lang="scss" scoped>

h3 {
  color: #bbb;
  font-weight: 100;
}

.cards-warapper {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.tag-detail-warapper {
  p {
    font-size: 0.9rem;
    span {
      color: black;
      font-weight: 600;
      margin-right: 1rem;
    }
  }
}

.embed-description {
  display: inline-block;
  color: #67757c !important;
  font-weight: 400 !important;
}

</style>
